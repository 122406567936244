import { createSlice } from "@reduxjs/toolkit";


const adminslice=createSlice({
    name:'admin',
    initialState:{value:{
        totalEmployee:'30'
    }},
    reducers:{

    }
})

export default adminslice.reducer;